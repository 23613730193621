import React, { useState } from "react";
import PrivacyModal from "./PrivacyModal";
import ComplaintsModal from "./ComplaintsModal";
import CookieModal from "./CookieModal";

export const MODAL_TYPES = {
  PRIVACY: "privacy",
  COMPLAINTS: "complaints",
  COOKIE: "cookie",
};

const Footer = ({ showModal = "" }) => {
  const [modal, setModal] = useState(showModal);

  return (
    <footer>
      {
        <PrivacyModal
          show={modal === MODAL_TYPES.PRIVACY}
          onHide={() => setModal(null)}
        />
      }
      {
        <ComplaintsModal
          show={modal === MODAL_TYPES.COMPLAINTS}
          onHide={() => setModal(null)}
          showPrivacyModal={() => setModal(MODAL_TYPES.PRIVACY)}
        />
      }
      {
        <CookieModal
          show={modal === MODAL_TYPES.COOKIE}
          onHide={() => setModal(null)}
          showPrivacyModal={() => setModal(MODAL_TYPES.PRIVACY)}
        />
      }

      <div className="container">
        <div className="ftr_cntnt">
          <div className="row">
            <div className="col-lg-12 col-12">
              <p>
                ClaimLion Law is a trading name of BlackLion Law LLP, a limited
                liability partnership registered in England &amp; Wales and
                regulated by the Solicitors Regulation Authority under SRA
                number 518911. A list of the members of the LLP is displayed at
                our registered office at Berkeley Square House, Berkeley Square,
                London W1J 6BD
              </p>
              <p className="pt-3">
                * If you cancel your claim after the 14-day cancellation period,
                you may be liable for any expenses we have incurred on your
                behalf up to the point of cancellation.
              </p>
            </div>
            {/*  <div className="col-lg-4 col-12 text-center">
              <img src={ftr} alt="ftr" />
            </div>*/}

            {/*<div style={{ maxWidth: "275px", maxHeight: "163px" }}>
              <div
                style={{
                  position: "relative",
                  paddingBottom: "59.1%",
                  height: "auto",
                  overflow: "hidden",
                }}
              >
                  <iframe
                  frameBorder="0"
                  scrolling="no"
                  allowtransparency="true"
                  src="https://cdn.yoshki.com/iframe/55845r.html"
                  style={{
                    border: "0px",
                    margin: "0px",
                    padding: "0px",
                    backgroundColor: "transparent",
                    top: "0px",
                    left: "0px",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                  }}
                ></iframe>
              </div>
            </div>*/}
          </div>
        </div>
        <div className="text-center">
          <div className="copyright">
            <ul>
              <li>
                <a
                  href="#privacy"
                  onClick={() => setModal(MODAL_TYPES.PRIVACY)}
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="#complaints"
                  onClick={() => setModal(MODAL_TYPES.COMPLAINTS)}
                >
                  Complaints Policy
                </a>
              </li>
              <li>
                <a
                  href="https://onlineplevincheck.co.uk/static/media/TERMS_OF_BUSINESS.788f5f2e.pdf"
                  target="_blank"
                >
                  Terms of Business
                </a>
              </li>
              <li>
                <a href="#cookie" onClick={() => setModal(MODAL_TYPES.COOKIE)}>
                  Cookie Policy
                </a>
              </li>
            </ul>
          </div>
          <p className="text-center">
            <b>© 2022 ClaimLion Law</b>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
