//import {useState} from 'react';
import { Api, CommonEndApi } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";
import { useErrorWebhook } from "./useErrorWebhook";

export const usePostCodeAddress = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const getPostcodeAddress = async (postcode, uuid) => {
    const response = await CommonEndApi.get(
      "api/get-addr-list?ProjectCode=" +
        EnvConstants.AppAdtopiaUPID +
        "&Environment=" +
        EnvConstants.AppEnv +
        "&postcode=" +
        postcode +
        "&uuid=" +
        uuid,
      {}
    ).catch(function (error) {
      const erroMessage = {
        serviceName: "GET POSTCODE ADDRESS LIST",
        errorReason: error.message,
        errorCode: error.code,
      };
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };
  const getSplitPostcodeAddress = async (AddressID, uuid) => {
    const response = await CommonEndApi.get(
      "api/get-addr-details?ProjectCode=" +
        EnvConstants.AppAdtopiaUPID +
        "&Environment=" +
        EnvConstants.AppEnv +
        "&addressId=" +
        AddressID +
        "&uuid=" +
        uuid,
      {}
    ).catch(function (error) {
      const erroMessage = {
        serviceName: "GET POSTCODE ADDRESS DETAILS",
        errorReason: error.message,
        errorCode: error.code,
      };
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };
  return { getPostcodeAddress, getSplitPostcodeAddress };
};
