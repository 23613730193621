import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import Split_1 from "./Components/Pages/Splits/Split_1";
import { RedirectProvider } from "./Contexts/RedirectContext";
// import Followup from "./Components/Pages/Splits/Followup";
import Preview from "./Components/Pages/Preview";
import OpenBankingProceed from "./Components/Pages/OpenBankingProceed";
import Unqualified from "./Components/Pages/Unqualified";
// import Signature from "./Components/Pages/Signature";
import IdUpload from "./Components/Pages/IdUpload";
import PreviewLoa from "./Components/Pages/PreviewLoa";
import NotFound from "./Components/Pages/NotFound";
import ADV_1 from "./Components/Pages/Advertorials/ADV_1";
import ADV1_Contact from "./Components/Pages/Advertorials/ADV1_Contact";
import BudsRedirectPage from "./Components/Pages/BudsRedirectPage";
import Proceed from "./Components/Pages/Proceed";
import PdfView from "./Components/Pages/PdfView";
import EnvDocView from "./Components/Pages/EnvDocView";
import EnvSignatureView from "./Components/Pages/EnvSignatureView";
// import Questionnaire from "./Components/Pages/NewQuestionnaire/Questionnaire";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";
import EnvZipView from "./Components/Pages/EnvZipView";
// import ErrorPage from "./Components/Pages/ErrorPage";

const CompletedFollowupQuestionnaire = lazy(() =>
  import("./Components/Pages/Followup/CompletedFollowupQuestionnaire")
);

const CompletedFollowupAdditionalVehicleDetails = lazy(() =>
  import(
    "./Components/Pages/Followup/CompletedFollowupAdditionalVehicleDetails"
  )
);

// const CompletedFollowupVehiclesForm = lazy(() =>
//   import(
//     "./Components/Pages/Followup/VehiclesForm/CompletedFollowupVehiclesForm"
//   )
// );

const CompletedFollowupVehiclesForm = lazy(() =>
  import(
    "./Components/Pages/Followup/VehiclesForm/CompletedFollowupVehiclesFormNew"
  )
);

// const CompletedFollowup = lazy(() =>
//   import("./Components/Pages/Followup/CompletedFollowup")
// );

const CompletedFollowup = lazy(() =>
  import("./Components/Pages/Followup/CompletedFollowupLandingPage")
);

const FollowupAdditionalVehicleDetails = lazy(() =>
  import("./Components/Pages/Followup/FollowupAdditionalVehicleDetails")
);

const FollowupVehiclesForm = lazy(() =>
  import("./Components/Pages/Followup/VehiclesForm/FollowupVehiclesForm")
);

const AdditionalVehicleDetails = lazy(() =>
  import("./Components/Pages/AdditionalVehicleDetails/AdditionalVehicleDetails")
);

const VehiclesForm = lazy(() =>
  import("./Components/Pages/VehiclesForm/VehiclesForm")
);

const Split_CL_PCP_V1_Landing_Page = lazy(() =>
  import("./Components/Pages/CL_PCP_V1/Split_CL_PCP_V1_Landing_Page")
);

const NewSignature = lazy(() =>
  import("./Components/Pages/NewSignature/NewSignature")
);

const Questionnaire = lazy(() =>
  import("./Components/Pages/NewQuestionnaire/Questionnaire")
);

const NewThankyou = lazy(() => import("./Components/Pages/NewThankYou"));
const CompletedNewThankyou = lazy(() =>
  import("./Components/Pages/CompletedNewThankYou")
);

const Followup = lazy(() => import("./Components/Pages/Followup/Followup"));

const FollowupSignature = lazy(() =>
  import("./Components/Pages/Followup/FollowupSignature")
);

const FollowupQuestionnaire = lazy(() =>
  import("./Components/Pages/Followup/FollowupQuestionnaire")
);

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: "" });
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <Suspense fallback="Loading...">
              <BrowserRouter>
                <Route exact path="/" component={HomePage} />
                <Route exact path={["/Split_1"]} component={Split_1} />
                {/* <Route exact path={"/questionnaire"} component={Questionnaire} /> */}
                {/* <Route exact path={"/followup"} component={Followup} /> */}
                <Route exact path={["/splash-page"]} component={Preview} />
                <Route exact path={"/proceed"} component={Proceed} />
                <Route exact path={"/unqualified"} component={Unqualified} />
                {/* <Route exact path={"/signature"} component={Signature} /> */}
                <Route exact path={["/fbpixel"]} component={FbPixel} />
                {/* <Route exact path={"/thankyou"} component={Thankyou} /> */}
                <Route
                  exact
                  path={"/buds-redirect-page"}
                  component={BudsRedirectPage}
                />
                <Route exact path={["/id-upload"]} component={IdUpload} />
                <Route exact path={["/preview"]} component={PreviewLoa} />
                <Route exact path={["/404"]} component={NotFound} />
                {/* <Route exact path={["/error"]} component={ErrorPage} /> */}
                <Route exact path={["/ADV_1"]} component={ADV_1} />
                <Route
                  exact
                  path={["/ADV1/contact"]}
                  component={ADV1_Contact}
                />
                <Route
                  exact
                  path={"/proceed-open-banking"}
                  component={OpenBankingProceed}
                />
                <Route
                  exact
                  path={["/:enviroment/:project_id/pdf/:doc_type/:filename"]}
                  component={PdfView}
                />

                <Route
                  exact
                  path={["/CL_PCP_V1"]}
                  component={Split_CL_PCP_V1_Landing_Page}
                />
                {/* <Route
                exact
                path={["/CL_PCP_V1_land"]}
                component={Split_CL_PCP_V1_Landing_Page}
              /> */}
                <Route exact path={["/signature"]} component={NewSignature} />

                <Route
                  exact
                  path={["/vehicle-insertion"]}
                  component={VehiclesForm}
                />

                <Route
                  exact
                  path={["/additional-vehicle-details"]}
                  component={AdditionalVehicleDetails}
                />

                <Route
                  exact
                  path={"/questionnaire"}
                  component={Questionnaire}
                />
                <Route exact path={"/thankyou"} component={NewThankyou} />
                <Route
                  exact
                  path={"/completed-users-thankyou"}
                  component={CompletedNewThankyou}
                />

                <Route exact path={["/followup"]} component={Followup} />

                {/* for completed users */}
                <Route
                  exact
                  path={["/followup/additional-vehicle-followup"]}
                  component={CompletedFollowup}
                />

                <Route
                  exact
                  path={"/followup/signature"}
                  component={FollowupSignature}
                />

                <Route
                  exact
                  path={"/followup/questionnaire"}
                  component={FollowupQuestionnaire}
                />

                <Route
                  exact
                  path={"/followup/additional-vehicle-details"}
                  component={FollowupAdditionalVehicleDetails}
                />

                <Route
                  exact
                  path={"/followup/vehiclesForm"}
                  component={FollowupVehiclesForm}
                />

                <Route
                  exact
                  path={"/followup/completed-questionnaire"}
                  component={CompletedFollowupQuestionnaire}
                />

                <Route
                  exact
                  path={"/followup/completed-additional-vehicle-details"}
                  component={CompletedFollowupAdditionalVehicleDetails}
                />

                <Route
                  exact
                  path={"/followup/completed-vehiclesForm"}
                  component={CompletedFollowupVehiclesForm}
                />

                <Route
                  exact
                  path={["/pdf/:docType/:filename"]}
                  component={EnvDocView}
                />

                <Route
                  exact
                  path={["/:signature/:filename"]}
                  component={EnvSignatureView}
                />
                <Route
                  exact
                  path={["/zipfiledownload"]}
                  component={EnvZipView}
                />

                <Route
                  exact
                  path={["/google", "/testing"]}
                  component={AdtopiaLoadDynamic}
                />
              </BrowserRouter>
            </Suspense>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
