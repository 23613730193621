import React from "react";
import Modal from "react-bootstrap/Modal";

function ComplaintsModal({ show, onHide }) {
  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <p className="modal-title">
          <b style={{ color: "#6c6c6c", fontSize: "14px" }}>
            At ClaimLion Law, we treat complaints seriously and strive to
            resolve them efficiently, transparently and fairly.
          </b>
        </p>
      </Modal.Header>

      <Modal.Body>
        <div>
          <h5 className="modal-title mb-3 fw-bold text-black">
            CONTACT DETAILS
          </h5>
          <p>
            <b>ClaimLion Law Complaints Department</b>
          </p>
          <p>79 College Rd</p>
          <p>Harrow</p>
          <p>HA1 1BD</p>
          <p>Telephone Number: 020 112 8976</p>
          <p>E-mail Address: contactus@claimlionlaw.com</p>
          <h5 className="modal-title text-center fw-bold text-black">
            COMPLAINTS STAGES
          </h5>
          <p>
            <b>1.Communication with caseworker</b>
          </p>
          <p>
            Your first point of contact will be the caseworker assigned to your
            case. The caseworker will consider your case fairly and efficiently,
            taking into consideration the seriousness of your complaint. Should
            a resolution not be reached, the caseworker may pass the complaint
            to the senior member of the firm or request a written formal
            complaint be sent to our offices.
          </p>
          <p>
            <b>2. Communication with department manager</b>
          </p>
          <p>
            The manager will be in contact with you to discuss your complaint
            and try reaching a resolution. Failing this you will be asked to put
            your complaint in writing or electronic mail with full details of
            the complaint to start the formal procedure.
          </p>
          <p>
            <b>Client care partner contact details:</b>
          </p>
          <p>Ms Negar Yazdani</p>
          <p>Address: 79 College Rd, Harrow, HA1 1BD</p>
          <p>
            Email Address{" "}
            <a href="mailto:negar.yazdani@blacklionlaw.com">
              negar.yazdani@blacklionlaw.com
            </a>
          </p>
          <p>
            <b>3.Acknowledging your complaint</b>
          </p>
          <p>
            We will send you a written or an electronic acknowledgement of your
            complaint. We reserve the right to decline to consider a complaint
            that is made more than six months after the complainant became aware
            of the cause of the complaint. However, we have discretion to waive
            this time limit and still undertake the complaint. We will confirm
            to you in writing or electronic mail if we are prepared to consider
            a complaint that has been made outside of the time scale of six
            months.
          </p>
          <p>
            <b>4. Investigating your complaint</b>
          </p>
          <p>
            Your complaint will be investigated by the manager. We endeavour to
            ensure that the person investigating the complaint has not been
            directly involved in the matter to which the complaint relates. The
            manager has the authority to settle complaints.
          </p>
          <p>
            <b>5. Responding to your complaint</b>
          </p>
          <p>
            Within four weeks of receiving your full written complaint,
            ClaimLion Law will send you either:
          </p>
          <p></p>
          <ol type="a">
            <li>
              An acknowledgement, which explains that ClaimLion Law is in
              receipt of your complaint in addition to indicating when ClaimLion
              Law will make further contact with you; or
            </li>
            <li>
              A response setting out ClaimLion Law’s full investigation of your
              complaint.
            </li>
          </ol>
          <p />
          <p>
            Within eight weeks of receiving your complaint, ClaimLion Law will
            send you either:
          </p>
          <p></p>
          <ol type="a">
            <li>
              A response setting out ClaimLion Law’s full investigation of your
              complaint; or
            </li>
            <li> A holding response of the following:</li>
          </ol>
          <p />
          <p></p>
          <ul style={{ listStyleType: "none" }}>
            <li>
              <ol type="a">
                <li>
                  {" "}
                  Explaining why ClaimLion Law are still not in a position to
                  provide you with a response, giving reasons for the further
                  delay and stating when we expect to be able to provide you
                  with the stated information; or
                </li>
                <li>
                  Informing you that you may refer the handling of the complaint
                  to the Legal Ombudsman if you are dissatisfied with the delay.
                </li>
              </ol>
            </li>
          </ul>
          <p />
          <p>
            <b>6. Redress</b>
          </p>
          <p>
            Where we decide that redress is an appropriate solution to the
            complaint, ClaimLion Law will provide you with fair compensation for
            any acts or omissions for which we are responsible and comply with
            any offer of redress which the complainant accepts. Appropriate
            redress will not always involve financial redress. It may involve an
            apology or an offer to redo the work.
          </p>
          <p>
            <b>7. Final response</b>
          </p>
          <p>
            If after receiving our response you’re still unhappy we will refer
            our decision to our Senior Partner who will then send you a final
            response.
          </p>
          <p>
            <b>8. If you are still unhappy</b>
          </p>
          <p>
            If you are still dissatisfied with our Final Response, or if a
            complaint is not resolved after eight weeks,
          </p>
          <p>you may refer the complaint to:</p>
          <p>
            <b>Legal Ombudsman</b>
          </p>
          <p>
            The Legal Ombudsman is an independent and impartial complaints
            handling body established by the Legal Services Act 2007 to deal
            with complaints against solicitors. The Legal Ombudsman may:
          </p>
          <ul>
            <li>
              Investigate the quality of professional service supplied by a
              solicitor to a client.
            </li>
            <li>
              Investigate allegations that a solicitor has breached rules of
              professional conduct.
            </li>
            <li>
              Investigate allegations that a solicitor has unreasonably refused
              to supply a professional service to a prospective client.
            </li>
            <li>
              Investigate allegations that a solicitor has persistently or
              unreasonably offered a professional service that the client does
              not want.
            </li>
          </ul>
          <p>
            Before it will consider a complaint the Legal Ombudsman generally
            requires that the firm's internal Complaints Procedure has been
            exhausted. If the Legal Ombudsman is satisfied that the firm's
            proposals for resolving a complaint are reasonable, it may decline
            to investigate further.
          </p>
          <p>The Legal Ombudsman's address is:</p>
          <p>
            PO Box 6806, Wolverhampton, WV 1 9WJ; telephone, 0300 555 0333
            <br />
            website,{" "}
            <a target="_blank" href="https://legalombudsman.org.uk">
              www.legalombudsman.org.uk
            </a>
            <br />
            email{" "}
            <a href="mailto:enquiries@legalombudsman.org.uk">
              enquiries@legalombudsman.org.uk
            </a>
          </p>
          <p>
            Alternative complaints bodies, e.g. Ombudsman Services, exist which
            are competent to deal with complaints about legal services should
            both you and this firm wish to use such a scheme. We do not agree to
            use such schemes on the basis that the Legal Ombudsman is an
            independent body which can consider your complaint, should you wish
            to raise your concerns with them.
          </p>
          <p>
            <b>The Solicitors Regulation Authority</b>
          </p>
          <p>
            The Solicitors Regulation Authority can help you if you are
            concerned about our behaviour. This could be for things like
            dishonesty, taking or losing your money or treating you unfairly
            because of your age, a disability or other characteristic. You can
            raise your concerns with the Solicitors Regulation Authority.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          onClick={onHide}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ComplaintsModal;
