import React from "react";
import { Modal } from "react-bootstrap";

const SupportAlert = ({ show, onHide }) => {
  return (
    <Modal show={show} size="md" centered>
      <Modal.Body>
        <div className="text-center py-3">
          <h4 className="p-0 m-0">Thank you for reporting the issue.</h4>
          <h4>We will look into it shortly.</h4>
          <button className="zip-btn " onClick={onHide}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SupportAlert;
