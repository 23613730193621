import React, { useContext, useEffect, useState } from "react";
import { useAdtopiaLoadApi } from "../Hooks/useAdtopiaLoadApi";
import {
  AppDomainWithoutProtocol,
  AppDomain,
  AppLpDefaultUrl,
  AppApDefaultUrl,
  AppAdtopiaUPID,
  AppReferrerURL,
} from "../Constants/EnvConstants";
import { queryString, urlParams } from "./QueryString";
import { UUID } from "./UUID";
import { useHistory } from "react-router-dom";
import { CheckRedirectUrl } from "../Utility/CheckRedirectUrl";
import RedirectContext from "../Contexts/RedirectContext";
// import * as AppSplitList from '../AppSplitList';
import AppSplitList from "../AppSplitList";
const AdtopiaLoadDynamic = () => {
  const history = useHistory();
  const encryptVal = urlParams.get("value");
  const splitNameFromUrl = urlParams.get('split_name');
  const { getUUID } = UUID();
  const { adtopiaLoadResponse, adtopiaLoadingErr } = useAdtopiaLoadApi();
  const { redirectUrl } = useContext(RedirectContext);
  const { isLpUrlExist } = CheckRedirectUrl();
  const field = "uuid";
  var redirectURL = "";
  var url = "";
  const [AppDynamicRoutePrefix, SetAppDynamicRoutePrefix] = useState(
    new URL(window.location.href).pathname.substring(1)
  );
  const [splitName, SetSplitName] = useState("");
  const [renderingSplit, setRenderingSplit] = useState("");
  useEffect(() => {
    (async () => {
      let uuidType = encryptVal ? "new" : "check";
      const getUUIDParam = await getUUID("", uuidType);

      if (queryString == "") {
        url = `${AppDomain}/${AppDynamicRoutePrefix}`;
      } else {
        url = `${AppDomain}/${AppDynamicRoutePrefix}?${queryString}`;
      }

      const uuid = getUUIDParam.data.response.uuid;
      if (encryptVal || (!splitNameFromUrl)) {
        const ip_address = getUUIDParam.data.response.ip_address;
        localStorage.setItem("ip_address", ip_address);
        const getdtopiaApi = await adtopiaLoadResponse(
          url,
          AppDomainWithoutProtocol,
          window.navigator.userAgent,
          ip_address,
          uuid,
          AppAdtopiaUPID,
          AppReferrerURL
        );
        if (adtopiaLoadingErr) {
          SetSplitName(AppLpDefaultUrl);
          history.push(
            "/" +
              AppDynamicRoutePrefix +
              "?uuid=" +
              uuid +
              "&split_name=" +
              AppLpDefaultUrl
          );
        }
        if (typeof getdtopiaApi !== "undefined") {
          const getdtopiaApiResponse = getdtopiaApi.data;
          if (getdtopiaApiResponse.page_type == "advertorial") {
            if (getdtopiaApiResponse.lp_url) {
              redirectURL = getdtopiaApiResponse.lp_url;
            } else {
              redirectURL = await isLpUrlExist();
            }
            redirectUrl.redirectUrl = redirectURL;
            if (getdtopiaApiResponse.response === 200) {
              if (
                getdtopiaApiResponse.domain_name === AppDomainWithoutProtocol
              ) {
                if (
                  getdtopiaApiResponse.url_param.indexOf("&" + field + "=") !=
                  -1
                ) {
                  SetSplitName(getdtopiaApiResponse.split_full_name);
                  history.push(
                    "/" +
                      AppDynamicRoutePrefix +
                      "?" +
                      getdtopiaApiResponse.url_param +
                      "&split_name=" +
                      getdtopiaApiResponse.split_full_name
                  );
                } else {
                  SetSplitName(getdtopiaApiResponse.split_full_name);
                  history.push(
                    "/" +
                      AppDynamicRoutePrefix +
                      "?" +
                      getdtopiaApiResponse.url_param +
                      "&uuid=" +
                      uuid +
                      "&split_name=" +
                      getdtopiaApiResponse.split_full_name
                  );
                }
              } else {
                SetSplitName(AppLpDefaultUrl);
                window.location.href = `${getdtopiaApiResponse.url}/${AppDynamicRoutePrefix}?uuid=${uuid}&split_name=${AppApDefaultUrl}`;
              }
            } else if (getdtopiaApiResponse.response === 404) {
              if (getdtopiaApiResponse.url_param != "") {
                if (
                  getdtopiaApiResponse.url_param.indexOf("&" + field + "=") !=
                  -1
                ) {
                  SetSplitName(AppApDefaultUrl);
                  history.push(
                    "/" +
                      AppDynamicRoutePrefix +
                      "?" +
                      getdtopiaApiResponse.url_param +
                      "&split_name=" +
                      AppApDefaultUrl
                  );
                } else {
                  SetSplitName(AppApDefaultUrl);
                  history.push(
                    "/" +
                      AppDynamicRoutePrefix +
                      "?" +
                      getdtopiaApiResponse.url_param +
                      "&uuid=" +
                      uuid +
                      "&split_name=" +
                      AppApDefaultUrl
                  );
                }
              } else {
                SetSplitName(AppApDefaultUrl);
                history.push(
                  "/" +
                    AppDynamicRoutePrefix +
                    "?uuid=" +
                    uuid +
                    "&split_name=" +
                    AppApDefaultUrl
                );
              }
            } else {
              if (getdtopiaApiResponse.url_param != "") {
                if (
                  getdtopiaApiResponse.url_param.indexOf("&" + field + "=") !=
                  -1
                ) {
                  SetSplitName(AppApDefaultUrl);
                  history.push(
                    "/" +
                      AppDynamicRoutePrefix +
                      "?" +
                      getdtopiaApiResponse.url_param +
                      "&split_name=" +
                      AppApDefaultUrl
                  );
                } else {
                  SetSplitName(AppApDefaultUrl);
                  history.push(
                    "/" +
                      AppDynamicRoutePrefix +
                      "?" +
                      getdtopiaApiResponse.url_param +
                      "&uuid=" +
                      uuid +
                      "&split_name=" +
                      AppApDefaultUrl
                  );
                }
              } else {
                SetSplitName(AppApDefaultUrl);
                history.push(
                  "/" +
                    AppDynamicRoutePrefix +
                    "?uuid=" +
                    uuid +
                    "&split_name=" +
                    AppApDefaultUrl
                );
              }
            }
          } else if (getdtopiaApiResponse.page_type == "landingpage") {
            if (getdtopiaApiResponse.response === 200) {
              if (
                getdtopiaApiResponse.domain_name === AppDomainWithoutProtocol
              ) {
                if (
                  getdtopiaApiResponse.url_param.indexOf("&" + field + "=") !=
                  -1
                ) {
                  SetSplitName(getdtopiaApiResponse.split_full_name);
                  history.push(
                    "/" +
                      AppDynamicRoutePrefix +
                      "?split_name=" +
                      getdtopiaApiResponse.split_full_name +
                      "&" +
                      getdtopiaApiResponse.url_param
                  );
                } else {
                  SetSplitName(getdtopiaApiResponse.split_full_name);
                  history.push(
                    "/" +
                      AppDynamicRoutePrefix +
                      "?split_name=" +
                      getdtopiaApiResponse.split_full_name +
                      "&" +
                      getdtopiaApiResponse.url_param +
                      "&uuid=" +
                      uuid
                  );
                }
              } else {
                SetSplitName(AppLpDefaultUrl);
                window.location.href = `${getdtopiaApiResponse.url}/${AppDynamicRoutePrefix}?uuid=${uuid}&split_name=${AppApDefaultUrl}`;
              }
            } else if (getdtopiaApiResponse.response === 404) {
              if (getdtopiaApiResponse.url_param != "") {
                if (
                  getdtopiaApiResponse.url_param.indexOf("&" + field + "=") !=
                  -1
                ) {
                  SetSplitName(AppLpDefaultUrl);
                  history.push(
                    "/" +
                      AppDynamicRoutePrefix +
                      "?split_name=" +
                      AppLpDefaultUrl +
                      "&" +
                      getdtopiaApiResponse.url_param
                  );
                } else {
                  SetSplitName(AppLpDefaultUrl);
                  history.push(
                    "/" +
                      AppDynamicRoutePrefix +
                      "?split_name=" +
                      AppLpDefaultUrl +
                      "&" +
                      getdtopiaApiResponse.url_param +
                      "&uuid=" +
                      uuid
                  );
                }
              } else {
                SetSplitName(AppLpDefaultUrl);
                history.push(
                  "/" +
                    AppDynamicRoutePrefix +
                    "?split_name=" +
                    AppLpDefaultUrl +
                    "&uuid=" +
                    uuid
                );
              }
            } else {
              if (getdtopiaApiResponse.url_param != "") {
                if (
                  getdtopiaApiResponse.url_param.indexOf("&" + field + "=") !=
                  -1
                ) {
                  SetSplitName(AppLpDefaultUrl);
                  history.push(
                    "/" +
                      AppDynamicRoutePrefix +
                      "?split_name=" +
                      AppLpDefaultUrl +
                      "&" +
                      getdtopiaApiResponse.url_param
                  );
                } else {
                  SetSplitName(AppLpDefaultUrl);
                  history.push(
                    "/" +
                      AppDynamicRoutePrefix +
                      "?split_name=" +
                      AppLpDefaultUrl +
                      "&" +
                      getdtopiaApiResponse.url_param +
                      "&uuid=" +
                      uuid
                  );
                }
              } else {
                SetSplitName(AppLpDefaultUrl);
                history.push(
                  "/" +
                    AppDynamicRoutePrefix +
                    "?uuid=" +
                    uuid +
                    "&split_name=" +
                    AppLpDefaultUrl
                );
              }
            }
          } else {
            SetSplitName(AppLpDefaultUrl);
            history.push(
              "/" +
                AppDynamicRoutePrefix +
                "?uuid=" +
                uuid +
                "&split_name=" +
                AppLpDefaultUrl
            );
          }
        } else {
          redirectURL =
            AppDynamicRoutePrefix + "?split_name=" + AppLpDefaultUrl;

          SetSplitName(AppLpDefaultUrl);
          history.push(
            "/" +
              AppDynamicRoutePrefix +
              "?split_name=" +
              AppLpDefaultUrl +
              "&uuid=" +
              uuid
          );
        }
        localStorage.setItem("redirectURL", redirectURL);
      } else {
        var split_Name = urlParams.get("split_name")
          ? urlParams.get("split_name")
          : "";
        var split_uuid = urlParams.get("uuid") ? urlParams.get("uuid") : "";
        console.log(split_Name, split_uuid);
        if (split_Name && split_uuid) {
          // SetSplitName(AppSplitList[split_Name]);
          SetSplitName(split_Name);
        } else {
          var split_Name = urlParams.get("split_name")
            ? urlParams.get("split_name")
            : AppLpDefaultUrl;
          history.push(
            `/${AppDynamicRoutePrefix}?split_name=${split_Name}&uuid=${uuid}`
          );
          // SetSplitName(AppSplitList[split_Name]);
          SetSplitName(split_Name);
        }
      }
    })();
  }, []);

  // code for AppSplitList with const rendering
  // useEffect(() => {
  //     if (splitName instanceof Promise) {
  //         splitName.then(module => {
  //             const SplitComponent = module.default;
  //             setRenderingSplit(<SplitComponent />);
  //         });
  //     }
  // }, [splitName]);
  console.log("splitName : ", splitName);
  return (
    <>
      {/* AppSplitList const rendering */}
      {/* {renderingSplit && renderingSplit}  */}

      <AppSplitList splitName={splitName} />
    </>
  );
};

export default AdtopiaLoadDynamic;
