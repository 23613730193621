import React from "react";
import logo from "../../../../assets/CL_PCP_V1/img/logo_1_white.png";
import starImage from "../../../../assets/img/review-star.png";
// import TrustBox from "../../../UI/TrustBox";

const Header = () => {
  return (
    <>
      <header id="hding">
        <div className="container">
          <div
            className="col-lg-8 offset-lg-2 col-12 text-center p-0"
            id="slide01"
          >
            <img src={logo} alt="" />
            {/* <h1>
              Do you think you were mis-sold on your PCP car finance agreement?
            </h1> */}
            {/* <h2>
              A court ruling means motorists may be able to make a PCP claim for
              unfair sales commission.
            </h2>
            <h2 style={{ marginBottom: "1rem" }}>
              You could reclaim £1,000s in compensation!
            </h2> */}
            {/* <h2 className="mb-3">
              You could be owed an <b>average refund of £3,000 per car</b> in
              compensation
            </h2>
            <h3>
              <strong className="header-strong-text">
                CHECK IF YOU QUALIFY TO MAKE A PCP CLAIM
              </strong>
            </h3>

            <a
              href="https://www.trustpilot.com/review/claimlionlaw.co.uk"
              target="_blank"
            >
              <img src={starImage} alt="" />
            </a> */}

            {/* <div className="d-flex align-items-center justify-content-center">
              <div className="trust-box-container my-3">
                <TrustBox />

                <div
                  className="d-flex align-items-center justify-content-center mb-1"
                  style={{ color: "white" }}
                >
                  <img src={starImage} alt="" />
                  <p className="mx-2 my-0">3.8</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-exclamation-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                  </svg>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="spacemob" style={{ display: "none" }} /> */}
        </div>
      </header>
    </>
  );
};

export default Header;
