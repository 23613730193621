import React, { useState } from "react";
import { useZipDownloadErrorWebHook } from "../../Hooks/useZipDownloadErrorWebHook";
import SupportAlert from "../Includes/Layouts/CL_PCP_V1/SupportAlert";

const ErrorPage = ({ token }) => {
  const { ZipDownloadErrorWebHookTrigger } = useZipDownloadErrorWebHook();

  const [modalShow, setModalShow] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  const HandelOnClick = () => {
    const errorMsg = {
      serviceName: "Support click",
      uuid: token,
      errorReason: "Support button clicked",
      errorCode: "Support button clicked",
    };

    if (clickCount == 0) {
      ZipDownloadErrorWebHookTrigger(errorMsg);
    }

    setClickCount(clickCount + 1);
    setModalShow(true);
  };

  return (
    <div>
      <SupportAlert show={modalShow} onHide={() => setModalShow(false)} />

      <div className="text-center container ">
        <div className="error_page_contents">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-emoji-frown"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
            <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.5 3.5 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.5 4.5 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
          </svg>
          <h2 className="d-block d-md-none " style={{ padding: "10px" }}>
            Sorry, we are unable to download the files.
            {/* <br /> */}
            Please click here to raise an alert to our support team.
          </h2>
          <h2 className="d-none d-md-block" style={{ padding: "10px" }}>
            Sorry, we are unable to download the files.
            <br />
            Please click here to raise an alert to our support team.
          </h2>
          <button className="common-btn" onClick={HandelOnClick}>
            Click for support
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
