import { ZipDownloadWebhookApi } from "../api/Api";
import { currentdate, currenttime } from "../Utility/CurrentDateTime";
import {
  AppAdtopiaUPID,
  AppEnv,
  ZipDownloadApiError,
} from "../Constants/EnvConstants";

export const useZipDownloadErrorWebHook = () => {
  const ZipDownloadErrorWebHookTrigger = (errorMsg, log = false) => {
    const message = `
          {
            Service Name : ${errorMsg.serviceName}
            Project ID : ${AppAdtopiaUPID}
            token: ${errorMsg.uuid}
            Error Reason : ${errorMsg.errorReason}
            Error Code : ${errorMsg.errorCode}
            Datetime : ${currentdate} ${currenttime}
            Environment : ${AppEnv}
          }
          `;

    log && console.log("errorMsg : ", message);
    if (ZipDownloadApiError == "true") {
      const response = ZipDownloadWebhookApi.post("", {
        text: message,
      });
    }
  };
  return { ZipDownloadErrorWebHookTrigger };
};
