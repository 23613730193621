import React, { useState, useEffect } from "react";
import { AppApiUrl } from "../../Constants/EnvConstants";
const PdfView = () => {
  const [pdf, setPdf] = useState();

  useEffect(() => {
    const Url = window.location.href;
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    const location = new URL(Url);
    const filePath = location.pathname;
    let url = AppApiUrl.replace(/\/$/, "");
    console.log(url + filePath + "?token=" + token);
    setPdf(url + filePath + "?token=" + token);
  }, []);

  // Disable right-click
  document.addEventListener("contextmenu", (e) => e.preventDefault());

  function ctrlShiftKey(e, keyCode) {
    return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
  }

  document.onkeydown = (e) => {
    // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
    if (
      e.keyCode === 123 ||
      ctrlShiftKey(e, "I") ||
      ctrlShiftKey(e, "J") ||
      ctrlShiftKey(e, "C") ||
      (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
    )
      return false;
  };

  return (
    <>
       <object width="100%" height="800" data={pdf} type="application/pdf"></object>
    </>
  );
};

export default PdfView;
