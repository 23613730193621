import React, { useContext, useState, useRef } from "react";
import { CheckUUID } from "../../Utility/CheckUUID";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import RadioButtonQuest from "../UI/RadioButtonQuest";
import InputButton from "../UI/InputButton";
import { useIdUpload } from "../../Hooks/useIdUpload";
import { queryString } from "../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import camera from '../../assets/img/camera.png';
import { DynamicRoutingSplit } from "../../Utility/DynamicRoutingSplit";


const IdUpload = () => {
  const { isCheck } = CheckUUID();
  const history = useHistory();
  const isCheckResponse = isCheck();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const { saveIdUpload, isLoading } = useIdUpload();
  const [selectedFile, setSelectedFile] = useState();
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [selectedFileType, setSelectedFileType] = useState();
  const [base64Image, setBase64Image] = useState();
  const uploadRef = useRef();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const {DynamicRouteSplitName,DynamicRouteNextPage}  = DynamicRoutingSplit('','id-upload');

  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters.uuid;
    if (visitorData) {
      var uuid = visitorData;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const nextSlide = (e) => {
    uploadRef.current.scrollIntoView({ behavior: "smooth" });
  }
  const userfile = {
    preview: undefined,
    title: undefined
  }
	const changeFilesHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		let imageFile = event.target.files[0];
		if (imageFile) {
			setIsFilePicked(true);
			let extension = event.target.files[0].type;
			let imgsize = event.target.files[0].size;

			let allowedExtensions = ['image/jpeg', 'image/png'];
			if (allowedExtensions.indexOf(extension) === -1) {
				setIsInValidfFile(true);
				setErrorMessage({ txt: 'The uploaded ID type should be an image in jpeg,png or jpg' });
			} else if (imgsize > 1024 * 1024 * 20) {
				setIsInValidfFile(true);
				setErrorMessage({ txt: 'Please choose a file less than 20mb' });
			} else {
				setSelectedFileType(event.target.name);
				setIsInValidfFile(false);
			}
			userfile.preview = URL.createObjectURL(imageFile);
			userfile.title = imageFile.name;

			if (imageFile) {
				let reader = new FileReader();
				reader.onload = _handleReaderLoaded.bind(this);
				reader.readAsDataURL(imageFile);
			}
		} else {
			setIsInValidfFile(true);
			setIsFilePicked(false);
			userfile.preview = undefined;
			userfile.title = undefined;
		}
	};
	const _handleReaderLoaded = (readerEvt) => {
		let binaryString = readerEvt.target.result;
		setBase64Image({
			base64TextString: binaryString
		});
	};
  const handleSubmit = async (event) => {
    event.preventDefault();
    let uploadData = '';
    if(isFilePicked){
      uploadData = {
        "imageFile": base64Image.base64TextString,
        "captureType": selectedFileType.substring(selectedFileType.indexOf("doc")),
        "documentType": selectedFileType.substr(0, selectedFileType.indexOf('_'))
      };
      const response = await saveIdUpload(
        uploadData,
        visitorParameters.visitor_parameters,
        formParameters,
        visitorParameters.data,
        queryString,
        'user_docs_store'
      );
      history.push(
        "/" + DynamicRouteNextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid  + "&splitName=" + DynamicRouteSplitName
      );
    }else{
      setIsInValidfFile(true);
      setErrorMessage({ txt: 'Please upload a file' });
    }
  }
  const uuid = getUuid();
  return (
    <>
      <GetVisitorsParams />
      <div className="Idupload">
        <section className="questionspart">
          <div className="container">
            <div className="row">
              <div className="offset-lg-3 col-xl-6 offset-md-1 col-md-10 col-12 col-sm-12">
                <form>
                  <div className="radio-box question animated fadeInUp">
                    <h4>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </h4>
                    <div className="form-group">
                      <div className="" id="question_group_68">
                        <RadioButtonQuest
                          className="radio"
                          labelName="Option-1"
                          labelClassName="br radioa"
                          labelCountString="A"
                          value="a"
                          name="68"
                          id="clik-001"
                          onClick={nextSlide}
                        />
                        <RadioButtonQuest
                          className="radio"
                          labelName="Option-2"
                          labelClassName="br radioa"
                          labelCountString="B"
                          value="b"
                          name="68"
                          id="clik-002"
                          onClick={nextSlide}
                        />
                        <RadioButtonQuest
                          className="radio"
                          labelName="Option-3"
                          labelClassName="br radioa"
                          labelCountString="C"
                          value="c"
                          name="68"
                          id="clik-003"
                          onClick={nextSlide}
                        />
                        <RadioButtonQuest
                          className="radio"
                          labelName="Option-4"
                          labelClassName="br radioa"
                          labelCountString="D"
                          value="d"
                          name="68"
                          id="clik-004"
                          onClick={nextSlide}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="radio-box question animated fadeInUp"
                    id="id-div" ref={uploadRef}
                  >
                    <h4>
                      {" "}
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.{" "}
                    </h4>
                    <button
                      type="button"
                      value=""
                      className="btn-camera button1"
                    >
                      <img src={camera} alt="" /> Lorem Ipsum is
                      simply dummy
                    </button>
                  
                    <div className="input-group custom-file-button mb-3">
                      <input
                        type="file"
                        className="form-control"
                        id="idTypeFile"
                        name="IDCard_doc"
                        placeholder="Upload picture of Your Id"
                        accept="image/*"
                        onChange={changeFilesHandler}
                        onClick={e => (e.target.value = null)}
                      />
                      <label className="input-group-text" htmlFor="idTypeFile">
                        Browse
                      </label>
                      {isInValidfFile &&
												<span
                        className="error_msg"
                        id="email_err"
                      >
                      {errorMessage.txt}</span>
											}
                    </div>
                    <InputButton
                      name="formSubmitBtn"
                      className="btn-next"
                      id="formSubmitBtn"
                      value="SUBMIT"
                      btnType="submit"
                      onClick={handleSubmit}
                      style={{ float: "none" }}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default IdUpload;
