import React, { Fragment, useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { AppApiUrl } from "../../Constants/EnvConstants";
import Header from "../Includes/Layouts/CL_PCP_V1/Header";
import Footer from "../Includes/Layouts/CL_PCP_V1/Footer";
import "../../assets/CL_PCP_V1/css/main.scss";
import "../../assets/css/common-contents.scss";
import downloading from "../../assets/img/download-file.gif";
import { Api } from "../../api/Api";
// import { useHistory } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import { useZipDownloadErrorWebHook } from "../../Hooks/useZipDownloadErrorWebHook";
// import { Modal } from "react-bootstrap";
import SupportAlert from "../Includes/Layouts/CL_PCP_V1/SupportAlert";

// const ctrlShiftKey = (e, keyCode) => {
//   return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
// };

const EnvZipView = () => {
  // const { docType, filename } = useParams();
  // const history = useHistory();
  const { ZipDownloadErrorWebHookTrigger } = useZipDownloadErrorWebHook();

  // const [doc, setDoc] = useState();
  const [timeLeft, setTimeLeft] = useState(10);
  const [reDownload, setReDownload] = useState(false);
  const [URL, setURL] = useState("");
  const [fileInformation, setFileInformation] = useState([]);
  const [allFileInformation, setAllFileInformation] = useState({});
  const [clickCount, setClickCount] = useState(0);
  const [error, setError] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [troubleShootClickCount, setTroubleShootClickCountClickCount] =
    useState(0);

  const query = new URLSearchParams(window.location.search);
  const token = query.get("token");

  function extractFilenameAndType(url) {
    // Extract the pathname from the URL
    const pathnameIndex = url.lastIndexOf("/");
    const pathname = url.substring(pathnameIndex + 1);

    // Split the pathname by '?' to separate the filename and type (extension)
    const parts = pathname.split("?");
    const filename = parts[0];

    // Extracting the file type from the filename
    const lastDotIndex = filename.lastIndexOf(".");
    const type = filename.substring(lastDotIndex + 1);

    return { filename, type };
  }

  useEffect(() => {
    // api call
    token && apiCall();
  }, [token]);

  const apiCall = async (secondCall) => {
    const errorMsgApiCall = {
      serviceName: "Api call",
      uuid: token,
      errorReason: "Api call failed",
      errorCode: "",
    };

    try {
      const response = await Api.post(`/pdf-file-download`, {
        token,
        event: secondCall ? "clicked" : "",
      });
      console.log({ response });
      const data = response.data;

      if (response.status != 200) {
        ZipDownloadErrorWebHookTrigger(errorMsgApiCall);
        return;
      }

      if (secondCall) {
        // const errorMsg = {
        //   serviceName: "Download click",
        //   uuid: token,
        //   errorReason: "Download button clicked",
        //   errorCode: "",
        // };
        // ZipDownloadErrorWebHookTrigger(errorMsg);
      }

      setAllFileInformation(data);

      setURL(data?.zip_url);

      const formattedDocumentsData = data?.documents?.map((data) => {
        const { filename, type } = extractFilenameAndType(data?.url);
        return {
          filename: filename,
          type: type,
        };
      });
      setFileInformation(formattedDocumentsData);
      setError(false);
    } catch (error) {
      console.log("error :", { error });
      ZipDownloadErrorWebHookTrigger(errorMsgApiCall);

      setError(true);
      return;
    }
  };

  useEffect(() => {
    const countdown = () => {
      setTimeLeft((prevTime) => prevTime - 1);
    };

    if (timeLeft > 0) {
      const timerId = setTimeout(countdown, 1000);
      return () => clearTimeout(timerId);
    } else {
      setReDownload(true);
    }
  }, [timeLeft]);

  useEffect(() => {
    const downloadFile = async () => {
      try {
        const fileUrl = URL;
        const response = await fetch(fileUrl);

        if (!response.ok && response?.status !== 200) {
          setError(true);

          const errorMsg = {
            serviceName: "Download Failed",
            uuid: token,
            errorReason: response?.statusText || "zip download failed",
            errorCode: response?.status,
          };
          ZipDownloadErrorWebHookTrigger(errorMsg);

          console.error(
            "Failed to download file. Response status:",
            response.status
          );
          return;
        }

        const blob = await response.blob();

        let fileName = allFileInformation?.file_name || "Files";
        fileName = fileName.replace(/\.zip$/i, "");

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = fileName; // Replace with the desired filename
        downloadLink.click();

        window.URL.revokeObjectURL(downloadLink.href);
      } catch (error) {
        console.error("Error downloading the file:", error);
      }
    };

    if (URL) {
      downloadFile();
    }
  }, [URL, clickCount]);

  // if (!doc) return <></>;

  return (
    <>
      {/* <object
        width="100%"
        height="800"
        data={doc}
        type="application/pdf"
        aria-label="Doc preview"
      ></object> */}

      {/* <Modal show={modalShow} size="md" centered>
        <Modal.Body>
          <div className="text-center py-3">
            <h4 className="p-0 m-0">Thank you for reporting the issue.</h4>
            <h4>We will look into it shortly.</h4>
            <button className="zip-btn " onClick={() => setModalShow(false)}>
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal> */}

      <SupportAlert show={modalShow} onHide={() => setModalShow(false)} />

      <div className="pcp_v1">
        <Header />
        {!error && (
          <div className="container">
            <div className="zip-download">
              <div className="zip-download-contents">
                <div className="contents">
                  <img src={downloading} className="zip-gif" />
                  {/* <h1>Thanks For Downloading!</h1> */}
                  <h3 style={{ fontWeight: 600 }}>
                    Your download will begin shortly.
                  </h3>
                  <p className="mb-0">
                    If the download does not start automatically, please click
                    the button below.
                  </p>
                  <div className="text-center">
                    {!reDownload && (
                      <p>
                        <span style={{ fontSize: "20px", fontWeight: 700 }}>
                          {timeLeft}
                        </span>
                      </p>
                    )}
                    {!!reDownload && (
                      <div id="zip-re-download">
                        <button
                          // href={URL + "&event=clicked"}
                          // target="_blank"
                          className="zip-btn"
                          // download
                          onClick={() => {
                            apiCall(true);
                            setClickCount(clickCount + 1);
                          }}
                        >
                          Download
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="zip-card-contents">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 d-flex">
                      <div className="card flex-fill">
                        <div className="card-body">
                          <h2 className="card-title">File Information</h2>
                          <p className="card-text">
                            You are about to download the following files:
                          </p>
                          <ul>
                            {fileInformation?.map((data, index) => {
                              return (
                                <Fragment key={index}>
                                  <li style={{ paddingBottom: "5px" }}>
                                    <div>
                                      File {index + 1} : {data?.filename}
                                    </div>
                                  </li>
                                </Fragment>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 d-flex">
                      <div className="card flex-fill">
                        <div className="card-body">
                          <h2 className="card-title">Instructions</h2>
                          <p className="card-text">
                            Once the download is complete, you can open the
                            files using a PDF reader such as Adobe Acrobat
                            Reader.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 d-flex">
                      <div className="card flex-fill">
                        <div className="card-body">
                          <h2 className="card-title">
                            Troubleshooting Instructions
                          </h2>
                          <p className="card-text">
                            If you are having trouble downloading the files,
                            please{" "}
                            <span
                              onClick={() => {
                                const errorMsg = {
                                  serviceName: "Troubleshooting click",
                                  uuid: token,
                                  errorReason: "Troubleshooting button clicked",
                                  errorCode: "",
                                };
                                if (troubleShootClickCount == 0) {
                                  ZipDownloadErrorWebHookTrigger(errorMsg);
                                }

                                setTroubleShootClickCountClickCount(
                                  troubleShootClickCount + 1
                                );
                                setModalShow(true);
                              }}
                            >
                              <a className="trouble-shoot">click here</a>
                            </span>{" "}
                            to raise an alert to our support team.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!!error && <ErrorPage token={token} />}
        <Footer />
      </div>
    </>
  );
};

export default EnvZipView;
