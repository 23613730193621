import React from "react";
import { DobYear, DobMonth, DobDay } from "../Constants/Constants";
import SelectBox from "../Components/UI/SelectBox";

const DateOfBirth = ({ textDob, validation, validationMsg }) => {
  return (
    <div className="form-group col-12">
      <fieldset className="scheduler-border">
        <legend className="scheduler-border">{textDob}</legend>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <SelectBox
              className="form-select"
              OptionValue={DobDay}
              name="DobDay"
              id="DobDay"
              myRef={validation({
                required: "Please Select Date",
              })}
              validationMsg={
                validationMsg.DobDay && validationMsg.DobDay.message
              }
            />
            <i className="tick fa" style={{ display: "none" }}></i>
            {/* <span id="dobDay_err" className="error_msg"></span> */}
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <SelectBox
              className="form-select"
              OptionValue={DobMonth}
              name="DobMonth"
              id="DobMonth"
              myRef={validation({
                required: "Please Select Month",
              })}
              validationMsg={
                validationMsg.DobMonth && validationMsg.DobMonth.message
              }
            />
            <i className="tick fa" style={{ display: "none" }}></i>
            {/* <span id="dobMonth_err" className="error_msg"></span> */}
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <SelectBox
              className="form-select"
              OptionValue={DobYear}
              name="DobYear"
              id="DobYear"
              myRef={validation({
                required: "Please Select Year",
              })}
              validationMsg={
                validationMsg.DobYear && validationMsg.DobYear.message
              }
            />
            <i className="tick fa" style={{ display: "none" }}></i>
            {/* <span id="dobYear_err" className="error_msg"></span> */}
          </div>
          <span id="dob_final_err" className="error_msg"></span>
        </div>
      </fieldset>
    </div>
  );
};

export { DateOfBirth };
