// import { lazy } from 'react';
// const Split_1 =  import('./Components/Pages/Splits/Split_1');

// export {
//     Split_1
// }

import React, { lazy } from "react";

const Split_1 = lazy(() => import("./Components/Pages/Splits/Split_1"));
const Split_CL_PCP_V1 = lazy(() =>
  import("./Components/Pages/CL_PCP_V1/Split_CL_PCP_V1_Landing_Page")
);

const AppSplitList = (props) => {
  const SplitListMap = {
    Split_1: Split_1,
    CL_PCP_V1: Split_CL_PCP_V1,
  };

  const SplitComponent = SplitListMap[props.splitName];
  return <>{SplitComponent && <SplitComponent />}</>;
};

export default AppSplitList;
